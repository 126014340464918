import {
    FETCH_DATA_RESUME_CASH, SET_DATA_RESUME_CASH, SET_LOGINS, ADD_LOGINS, ADD_OPENED_TICKETS, UPDATE_TICKET,
    UPDATE_RESUME_CASH_STATE, FETCH_OPENED_TICKETS, SET_OPENED_TICKETS, SET_CLOSED_TICKETS, SET_FOREGROUND,
    SET_TICKETS_STATS, FETCH_TICKETS_STATS, SET_CC, REMOVE_TICKET, SET_VERSION_DB, SET_CC_VEHICLES
} from "./actionTypes"

const initialState = {
    customer_id: '150461130934066',
    dataResumeCash: [],
    openedTickets: [],
    closedTickets: [],
    ticketsStats: [],
    foreground: false,
    logins: [],
    cc: [],
    cc_vehicles: [],
    versionDB: '0.0.0'
}

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_RESUME_CASH:
            return {
                ...state,
                dataResumeCash: true
            }
        case SET_DATA_RESUME_CASH:
            return {
                ...state,
                dataResumeCash: action.payload
            }

        case UPDATE_RESUME_CASH_STATE:
            return {
                ...state,
                dataResumeCash: action.payload,
            };

        case UPDATE_TICKET:
            
            const { id, ...payload } = action.payload;

            // Filtrar el ticket si ha cambiado su estado a 'closed'
            const openedTicketsUpdated = state.openedTickets.filter(ticket => {
                // Mantener el ticket en openedTickets solo si no es el ticket actualizado o si sigue abierto
                return !(ticket.id === id && payload.closed === 1);
            });

            // Adicionalmente, si mantienes una lista de tickets cerrados, puedes agregar el ticket a esa lista
            // Esto depende de si el ticket actualizado está cerrado y si tienes tal lista en tu estado
            let closedTicketsUpdated = [...state.closedTickets];
            if (payload.closed === 1) {
                // Encuentra el ticket actualizado y agrégalo a closedTickets si no está ya presente
                const ticketExistsInClosed = state.closedTickets.find(ticket => ticket.id === id);
                if (!ticketExistsInClosed) {
                    const updatedTicket = { ...state.openedTickets.find(ticket => ticket.id === id), ...payload };
                    closedTicketsUpdated.push(updatedTicket);
                }
            }

            return {
                ...state,
                openedTickets: openedTicketsUpdated,
                closedTickets: closedTicketsUpdated, // Asegúrate de que esta parte del estado exista si planeas usarla
            };
        
        case REMOVE_TICKET:
            return {
                ...state,
                openedTickets: state.openedTickets.filter(ticket => ticket.id !== action.payload),
                closedTickets: state.closedTickets.filter(ticket => ticket.id !== action.payload),
            };
        
        case FETCH_OPENED_TICKETS:
            return {
                ...state,
                openedTickets: true
            }

        case SET_OPENED_TICKETS:
            return {
                ...state,
                openedTickets: action.payload
            }

        case SET_CLOSED_TICKETS:
            return {
                ...state,
                closedTickets: action.payload
            }

        case ADD_OPENED_TICKETS:
            return {
                ...state,
                openedTickets: [action.payload, ...state.openedTickets]
            }


        case FETCH_TICKETS_STATS:
            return {
                ...state,
                ticketsStats: true
            }

        case SET_TICKETS_STATS:
            return {
                ...state,
                ticketsStats: action.payload
            }

        case SET_LOGINS:
            return {
                ...state,
                logins: action.payload
            }

        case ADD_LOGINS:
            return {
                ...state,
                logins: [action.payload, ...state.logins]
            }

        case SET_CC:
            return {
                ...state,
                cc: action.payload
            }
        
        case SET_CC_VEHICLES:
            return {
                ...state,
                cc_vehicles: action.payload
            }

        case SET_FOREGROUND:
            return {
                ...state,
                foreground: action.payload
            }
        
        case SET_VERSION_DB:
            return {
                ...state,
                versionDB: action.payload
            }

        default:
            return state
    }
}

export default dataReducer
