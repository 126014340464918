import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';

import {
    setTicketsStats, fetchTicketsStats, addOpenedTickets, setLogins, addLogins, setVersionDB, 
    fetchDataResumeCash, setDataResumeCash, setOpenedTickets, setClosedTickets, updateTicket, removeTicket, fetchOpenedTickets, setCC,
    setCCVehicles
} from "../store/data/actions";
import { setDataCash, addDataCash } from "../store/cash/actions"
import { setCurrentCompany } from "../store/companies/actions";
import { collection, doc, onSnapshot, orderBy, query, where, getDocs, getDoc, limit, startAfter, startAt } from "firebase/firestore";
import { firestore } from "../firebase";
import moment from 'moment';
import "moment/locale/es";
import { isEqual } from 'lodash';


import numeral from 'numeral'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ListenerDB = (props) => {

    const dispatch = useDispatch();
    const { currentCompany } = props;
    const previousCompanyData = useRef(null);

    /////////////// COMPANY DATA

    useEffect(() => {
        let unsubscribe;

        const fetchData = async () => {
            const userId = currentCompany.id;
            const usersRef = collection(firestore, 'users');
            const userIdRef = doc(usersRef, userId);

            try {
                // Escucha cambios solo en el campo "company" del documento
                unsubscribe = onSnapshot(userIdRef, { fields: ['company'] }, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        const companyData = docSnapshot.get('company');
                        companyData.id = userId;
                        if (!isEqual(companyData, previousCompanyData.current)) {
                            // Realiza las acciones que deseas hacer cuando el campo "company" cambie
                            dispatch(setCurrentCompany(companyData));
                            previousCompanyData.current = companyData;
                        }
                    }
                });
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };

        fetchData();

        // Devuelve una función de limpieza para detener el listener cuando el componente se desmonte
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [props.currentCompany.id]); // Asegúrate de agregar las dependencias necesarias



    useEffect(() => {
        let unsubscribe;

        const fetchData = () => {
            const userId = currentCompany.id;
            const usersRef = collection(firestore, "users");
            const userIdRef = doc(usersRef, userId);

            try {
                unsubscribe = onSnapshot(userIdRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        const userData = docSnapshot.data();

                        console.log('userData', userData)
                        
                        // VERSION_DB
                        const versionDB = userData?.app_config?.version_db || 0;
                        dispatch(setVersionDB(versionDB));

                        // TICKETSTATS
                        const ticketsStats = userData?.ticket_stats || [];
                        dispatch(setTicketsStats(ticketsStats));
                        // RESUME CASH 
                        const resumeCashArray = userData?.resume_cash || [];
                        const jsonFormat = resumeCashArray.array
                            .replace(/\(/g, '[') // Reemplaza "(" por "["
                            .replace(/\)/g, ']') // Reemplaza ")" por "]"
                            .replace(/'/g, '"'); // Reemplaza las comillas simples por comillas dobles
                        const dataArray = JSON.parse(jsonFormat);
                        let totalCash = 0;
                        dataArray.forEach((element) => {
                            totalCash += element[2];
                        });
                        dataArray.forEach((element) => {
                            element[3] = parseInt((element[2] / totalCash) * 100);
                        });
                        dataArray.unshift([1, 'TOTAL', totalCash, 100])
                        // Actualiza el estado de Redux con los datos
                        dispatch(setDataResumeCash(dataArray));
                        // dispatch({ type: UPDATE_RESUME_CASH_STATE, payload: dataArray });
                    }
                });


            } catch (error) {
                console.log('Error fetching ticket stats:', error);
            }
        };

        fetchData();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }

        };

        // No se retorna nada aquí
    }, [props.currentCompany.id, dispatch]);




    /////////////// LOGINS
    useEffect(() => {
        let unsubscribe; // Declarar la variable en el ámbito superior
        const fetchData = async () => {
            const userId = currentCompany.id;
            const loginsRef = collection(doc(firestore, 'users', userId), 'logins');
            const fechaLimite = new Date();
            fechaLimite.setDate(fechaLimite.getDate() - 7);
            const q = query(loginsRef, where('created', '>', fechaLimite.toISOString()), orderBy('created', 'desc'));


            // Obtener documentos iniciales
            const initialQuerySnapshot = await getDocs(q);
            const initialDocuments = [];
            let last_doc = null;
            initialQuerySnapshot.forEach((doc) => {
                const docData = doc.data();
                if (last_doc == null) {
                    last_doc = docData.created;
                }
                initialDocuments.push(docData);
            });

            dispatch(setLogins(initialDocuments));

            try {
                unsubscribe = onSnapshot(q, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const loginData = change.doc.data();
                        if (loginData.created > last_doc) {
                            dispatch(addLogins(loginData));
                            toast.info('Nuevo inicio de sesión: ' + loginData.fullName)
                        }


                    });
                });


            } catch (error) {
                console.log('Error fetching logins:', error);
            }

        };

        fetchData();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }

        };

    }, [props.currentCompany.id]);



    ///////// TICKETS //////////////

    useEffect(() => {
        let unsubscribe; // Declarar la variable en el ámbito superior

        const fetchData = async () => {
            const ticketsRef = collection(firestore, "users", currentCompany.id, "tickets");

            // Consulta para tickets abiertos
            const openTicketsQuery = query(ticketsRef, where("closed", "==", 0), orderBy('init_date', 'desc'));

            // Consulta para tickets cerrados
            const closedTicketsQuery = query(ticketsRef, where("closed", "==", 1), orderBy('init_date', 'desc'));


            // Obtener tickets abiertos
            const openTicketsSnapshot = await getDocs(openTicketsQuery);
            const openTickets = openTicketsSnapshot.docs.map(doc => doc.data());
            dispatch(setOpenedTickets(openTickets));

            // Obtener tickets cerrados (opcionalmente, si necesitas cargarlos inicialmente)
            const closedTicketsSnapshot = await getDocs(closedTicketsQuery);
            const closedTickets = closedTicketsSnapshot.docs.map(doc => doc.data());

            dispatch(setClosedTickets(closedTickets));

            // BUSCAMOS EL ID MAS ALTO DE LOS TICKETS ABIERTOS Y CERRADOS
            let last_id = 0;
            openTickets.forEach((ticket) => {
                if (ticket.id > last_id) {
                    last_id = ticket.id;
                }
            });
            closedTickets.forEach((ticket) => {
                if (ticket.id > last_id) {
                    last_id = ticket.id;
                }
            });

            // Escuchar cambios en la colección de tickets
            unsubscribe = onSnapshot(ticketsRef, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    const ticket = change.doc.data();
                    if (change.type === "added") {
                        if (ticket.closed === 0 && ticket.id > last_id) { // Si el ticket está abierto
                            toast.info("Nuevo ticket: #" + ticket.plate);
                            dispatch(addOpenedTickets(ticket));
                        }

                    } else if (change.type === "modified") {
                        if (ticket.closed === 0) {
                            // Actualizar los tickets abiertos si es necesario
                        } else {
                            if (ticket.canceled === 1) {
                                toast.error("Ticket cancelado: #" + ticket.plate);
                            } else {
                                toast.info("Ticket cerrado: #" + ticket.plate);
                            }

                        }

                        dispatch(updateTicket(ticket))

                    } else if (change.type === "removed") {
                        dispatch(removeTicket(ticket.id))
                    }
                });
            });
        };

        fetchData();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };

    }, [props.currentCompany.id]);









    ///////// CC //////////////

    useEffect(() => {

        const ccRef = collection(firestore, "users", currentCompany.id, "cc");
        const q = query(ccRef, orderBy('fullName', 'asc'))

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const cc = [];
            querySnapshot.forEach((doc) => {
                const d = doc.data();
                if (d.closed == 0)
                    cc.push(d);
            });

            dispatch(setCC(cc));
        });

        return () => {
            unsubscribe();
        };
    }, [props.currentCompany.id]);


    ///////// CC_VEHICLES //////////////

    useEffect(() => {

        const ccVehiclesRef = collection(firestore, "users", currentCompany.id, "cc_vehicles");
        const q = query(ccVehiclesRef, orderBy('fullName', 'asc'))

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const cc_vehicles = [];
            querySnapshot.forEach((doc) => {
                const d = doc.data();
                if (d.deleted == 0)
                    cc_vehicles.push(d);
            });
            
            dispatch(setCCVehicles(cc_vehicles));
        });

        return () => {
            unsubscribe();
        };
    }, [props.currentCompany.id]);


    ///// CASH ///////////////////////



    useEffect(() => {
        let unsubscribe; // Declarar la variable en el ámbito superior

        const fetchDocuments = async () => {
            dispatch(setDataCash([]));
            const cashRef = collection(firestore, "users", props.currentCompany.id, "cash");
            const q = query(cashRef, where('type', '==', 'init'), orderBy('date', 'asc'));
            const querySnapshot = await getDocs(q);

            const quantity = 10
            const num = querySnapshot.docs.length - quantity < 0 ? 0 : querySnapshot.docs.length - quantity;
            const lastInitDoc = querySnapshot.docs[num];
            const qNew = query(cashRef, orderBy('id', 'asc'), startAt(lastInitDoc));

            // Obtener documentos iniciales
            const initialQuerySnapshot = await getDocs(qNew);
            const initialDocuments = [];

            let last_id;
            initialQuerySnapshot.forEach((doc) => {
                const docData = doc.data();
                last_id = docData.id;
                initialDocuments.push(docData);
            });

            dispatch(setDataCash(initialDocuments));

            unsubscribe = onSnapshot(qNew, (snapshot) => {

                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        const docData = change.doc.data();
                        if (docData.id > last_id) {
                            dispatch(addDataCash(docData));

                            toast.info("Nuevo movimiento de caja " + numeral(docData.value).format('$0,0.00'))
                        }
                        // Nuevo registro añadido a la colección
                    }
                });
            });


        };

        fetchDocuments();

        return () => {
            // Detener la suscripción cuando el componente se desmonte
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [props.currentCompany.id]);


    return <ToastContainer limit={5} pauseOnHover={false} position="bottom-right"
        autoClose={5000} theme="colored" />
}





const mapStateToProps = (state) => {
    return {
        currentCompany: state.companies.currentCompany,
        dataResumeCash: state.data.dataResumeCash,
        logins: state.data.logins,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTicketsStats: (ticketsStats) => dispatch(setTicketsStats(ticketsStats)),
        fetchDataResumeCash: () => dispatch(fetchDataResumeCash()),
        setLogins: (logins) => dispatch(setLogins(logins)),
        fetchOpenedTickets: () => dispatch(fetchOpenedTickets()),
        setOpenedTickets: (tickets) => dispatch(setOpenedTickets(tickets)),
        setCC: (cc) => dispatch(setCC(cc))
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(ListenerDB);