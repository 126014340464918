import {
    FETCH_DATA_COMPANIES,
    SET_DATA_COMPANIES,
    SET_CURRENT_COMPANY,
    DELETE_COMPANY
} from "./actionTypes"

const initialState = {
   companies:[],
   currentCompany: {},
}

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_COMPANIES:

            let current = localStorage.getItem('currentCompany')
            console.log('COMPANIA', action.payload)
            try {

            if (current) {
                const parsed = JSON.parse(current)
                current = action.payload.find(company => company.id === parsed.id)
                if (!current) current = action.payload[0]
            } else {
                current = action.payload[0]
                localStorage.setItem('currentCompany', JSON.stringify(current))
            }

            }
            catch (e) {
                current = action.payload[0]
                localStorage.setItem('currentCompany', JSON.stringify(current))
            }
            return {
                ...state,
                companies: action.payload, 
                currentCompany: current

            }
        
        case SET_CURRENT_COMPANY:
            localStorage.setItem('currentCompany', JSON.stringify(action.payload))
            return {
                ...state,
                currentCompany: action.payload
            }
        
        case DELETE_COMPANY:
            const companies = state.companies.filter(company => company.id !== action.payload.id)
            return {
                ...state,
                companies
            }

        default:
            return state
    }
}

export default dataReducer
